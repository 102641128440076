import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {TableHeaderInterface} from '../../../../../interfaces/table-header.interface'

import {
  CustomerData,
  CustomerResultInterface,
} from '../../../crm/customer-management/interfaces/customer-result.interface'
import {UserSingleResultInterface} from '../../user-management/interfaces/user-single-result.interface'
import {AffiliatorResultInterface, AffiliatorData} from '../interfaces/affilator-result.interface'

import {
  GetAllAffiliatorParam,
  GetAllDonationByAffiliator,
  GetAllAffiliator,
  GetTotalDonationMember,
  GetAllCustomerAffiliatorParam,
  GetTotalDonaturMember,
  GetCustomerByKorca,
  SubmitAffiliator,
  UpdateAffiliator,
  DeleteAffiliator,
  GetAllAffiliatorByKorca,
  GetAllAffiliatorPartner,
  TotalVolunteer,
  GetAllDonationByAffiliatorParam,
} from '../providers/affiliator-provider'
import {
  DonationData,
  DonationResultInterface,
} from '../../../program/donation/interfaces/donation-result.interface'

interface AffiliatorCompanyState {
  affiliatorCompanyLoading: boolean
  affiliatorCompanyItems: AffiliatorData[]
  affiliatorCompanySelectDatas: SelectData[]
  affiliatorCompanyModel?: AffiliatorResultInterface
  getAffiliatorCompany: (params: GetAllAffiliatorParam) => void

  // Get History Donation by Affiliator
  historyDonationAffiliatorLoading: boolean
  historyDonationAffiliatorModel: DonationResultInterface
  historyDonationAffiliatorItems: DonationData[]
  getHistoryDonationAffiliator: (params: GetAllDonationByAffiliatorParam) => void

  // Partner Affiliator
  affiliatorPartnerLoading: boolean
  affiliatorPartnerItems: AffiliatorData[]
  affiliatorPartnerSelectDatas: SelectData[]
  affiliatorPartnerModel?: AffiliatorResultInterface
  getAffiliatorPartner: (params: GetAllAffiliatorParam) => void

  // Total Relawan
  totalRelawanLoading: boolean
  totalRelawanItems: number
  getTotalRelawan: () => void

  getAffiliatorCompanyByKorca: (id: string, params: GetAllAffiliatorParam) => void
  affiliatorCompanyByKorcaLoading: boolean
  affiliatorCompanyByKorcaItems: AffiliatorData[]
  affiliatorCompanyByKorcaSelectDatas: SelectData[]
  affiliatorCompanyByKorcaModel?: AffiliatorResultInterface

  onDelete: (id: string, params: GetAllAffiliatorParam) => void
  // Get total donation member
  totalDonationMemberLoading: boolean
  totalDonationMember: number
  getTotalDoantionMember: (id: string) => void

  // Get total donatur member
  totalDonaturMemberLoading: boolean
  totalDonaturMember: number
  getTotalDoanturMember: (id: string) => void

  // Get Customers by korca
  customerByKorcaLoading?: boolean
  customerByKorcaItems?: CustomerData[]
  customerByKorcaModel?: CustomerResultInterface
  getCustomerByKorca?: (params: GetAllCustomerAffiliatorParam) => void

  customerHeaders: TableHeaderInterface[]
}

export const useAffiliatorCompanyStore = create<AffiliatorCompanyState>((set, get) => ({
  historyDonationAffiliatorLoading: false,
  historyDonationAffiliatorItems: [],
  historyDonationAffiliatorModel: undefined,
  getHistoryDonationAffiliator: async (params: GetAllDonationByAffiliatorParam) => {
    set({historyDonationAffiliatorLoading: true})
    const response = await GetAllDonationByAffiliator(params)
    set({historyDonationAffiliatorModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({
          historyDonationAffiliatorItems: response.data.items,
        })
      } else {
        set({historyDonationAffiliatorItems: []})
      }
    } else {
      set({historyDonationAffiliatorItems: []})
    }
    set({historyDonationAffiliatorLoading: false})
  },
  affiliatorCompanyLoading: false,
  affiliatorCompanyItems: [],
  affiliatorCompanySelectDatas: [],
  affiliatorCompanyModel: undefined,
  getAffiliatorCompany: async (params: GetAllAffiliatorParam) => {
    set({affiliatorCompanyLoading: true})
    const response = await GetAllAffiliator(params)
    set({affiliatorCompanyModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []

        set({
          affiliatorCompanyItems: response.data.items,
          affiliatorCompanySelectDatas: transformDatas,
        })
      } else {
        set({affiliatorCompanyItems: [], affiliatorCompanySelectDatas: []})
      }
    } else {
      set({affiliatorCompanyItems: [], affiliatorCompanySelectDatas: []})
    }
    set({affiliatorCompanyLoading: false})
  },
  affiliatorPartnerLoading: false,
  affiliatorPartnerItems: [],
  affiliatorPartnerSelectDatas: [],
  affiliatorPartnerModel: undefined,
  getAffiliatorPartner: async (params: GetAllAffiliatorParam) => {
    set({affiliatorPartnerLoading: true})
    const response = await GetAllAffiliatorPartner(params)
    set({affiliatorPartnerModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []

        set({
          affiliatorPartnerItems: response.data.items,
          affiliatorPartnerSelectDatas: transformDatas,
        })
      } else {
        set({affiliatorPartnerItems: [], affiliatorPartnerSelectDatas: []})
      }
    } else {
      set({affiliatorPartnerItems: [], affiliatorPartnerSelectDatas: []})
    }
    set({affiliatorPartnerLoading: false})
  },
  affiliatorCompanyByKorcaLoading: false,
  affiliatorCompanyByKorcaItems: [],
  affiliatorCompanyByKorcaSelectDatas: [],
  affiliatorCompanyByKorcaModel: undefined,
  getAffiliatorCompanyByKorca: async (id: string, params: GetAllAffiliatorParam) => {
    set({affiliatorCompanyByKorcaLoading: true})
    const response = await GetAllAffiliatorByKorca(id, params)
    set({affiliatorCompanyByKorcaModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({
          affiliatorCompanyByKorcaItems: response.data.items,
        })
      } else {
        set({affiliatorCompanyByKorcaItems: [], affiliatorCompanyByKorcaSelectDatas: []})
      }
    } else {
      set({affiliatorCompanyByKorcaItems: [], affiliatorCompanyByKorcaSelectDatas: []})
    }
    set({affiliatorCompanyByKorcaLoading: false})
  },
  onDelete: async (id: string, params: GetAllAffiliatorParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({affiliatorCompanyLoading: true})
      const response = await DeleteAffiliator(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getAffiliatorCompany(params)
      set({affiliatorCompanyLoading: false})
    }
  },
  // Get total relawan
  totalRelawanLoading: false,
  totalRelawanItems: 0,
  getTotalRelawan: async () => {
    set({totalRelawanLoading: true})
    const response = await TotalVolunteer()
    if (response.status) {
      set({totalRelawanItems: response.data as number})
    } else {
      set({totalRelawanItems: 0})
    }
    setTimeout(() => {
      set({totalRelawanLoading: false})
    }, 2000)
  },
  //Get total Donasi
  totalDonationMemberLoading: false,
  totalDonationMember: 0,
  getTotalDoantionMember: async (id: string) => {
    set({totalDonationMemberLoading: true})
    const response = await GetTotalDonationMember(id)
    if (response.status) {
      set({totalDonationMember: response.data as number})
    } else {
      set({totalDonationMember: 0})
    }
    setTimeout(() => {
      set({totalDonationMemberLoading: false})
    }, 2000)
  },

  // Total Donatur
  totalDonaturMemberLoading: false,
  totalDonaturMember: 0,
  getTotalDoanturMember: async (id: string) => {
    set({totalDonaturMemberLoading: true})
    const response = await GetTotalDonaturMember(id)
    if (response.status) {
      set({totalDonaturMember: response.data as number})
    } else {
      set({totalDonaturMember: 0})
    }
    setTimeout(() => {
      set({totalDonaturMemberLoading: false})
    }, 2000)
  },

  customerHeaders: [],

  customerByKorcaLoading: false,
  customerByKorcaItems: [],
  customerByKorcaModel: undefined,
  getCustomerByKorca: async (params: GetAllCustomerAffiliatorParam) => {
    set({customerByKorcaLoading: true})
    const response = await GetCustomerByKorca(params)
    set({customerByKorcaModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({
          customerByKorcaItems: response.data.items,
        })
      } else {
        set({customerByKorcaItems: []})
      }
    } else {
      set({customerByKorcaItems: []})
    }
    set({customerByKorcaLoading: false})
  },
}))

interface AffiliatorFormState {
  field?: AffiliatorData
  setField?: (item: AffiliatorData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  onSubmit: () => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useAffiliatorCompanyFormStore = create<AffiliatorFormState>((set, get) => ({
  field: undefined,
  setField: (item: AffiliatorData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'avatar',
        title: 'Avatar',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'avatar',
        value: get().field?.avatar,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        id: 'email',
        title: 'Email',
        placeholder: 'Email...',
        type: 'email',
        name: 'email',
        value: get().field?.email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'phone_number',
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phone_number',
        value: get().field?.phone_number ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'password',
        title: 'Password',
        placeholder: 'Password...',
        type: 'password',
        name: 'password',
        value: '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'full_name',
        title: 'Full Name',
        placeholder: 'Full Name...',
        type: 'text',
        name: 'full_name',
        value: get().field?.full_name,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'role_id',
        title: 'Role',
        placeholder: 'Role...',
        type: 'select-with-text',
        name: 'role_id',
        value: {
          label: get().field?.role_name,
          value: get().field?.role_id,
        },
        selectData: [{label: 'Relawan', value: '04580a88-fada-4313-a922-0377fc3ec1ba'}],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      // {
      //   id: 'percentage_fee',
      //   title: 'Percentage Fee Affiliate Program',
      //   placeholder: 'Percentage Fee',
      //   type: 'number',
      //   name: 'percentage_fee',
      //   value: get().field?.percentage_fee,
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },

      // {
      //   id: 'province_id',
      //   title: 'Provinsi',
      //   placeholder: 'Provinsi...',
      //   type: 'select-with-text',
      //   name: 'province_id',
      //   value: {
      //     label: get().field?.province_name,
      //     value: get().field?.province_id,
      //   },
      //   selectData: [],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      // {
      //   id: 'city_id',
      //   title: 'Kota/Kabupaten',
      //   placeholder: 'Kota/Kabupaten...',
      //   type: 'select-with-text',
      //   name: 'city_id',
      //   value: {
      //     label: get().field?.city_name,
      //     value: get().field?.city_id,
      //   },
      //   selectData: [],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      // {
      //   id: 'district_id',
      //   title: 'Kecamatan',
      //   placeholder: 'Kecamatan...',
      //   type: 'select-with-text',
      //   name: 'district_id',
      //   value: {
      //     label: get().field?.district_name,
      //     value: get().field?.district_id,
      //   },
      //   selectData: [],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      // {
      //   id: 'subdistrict_id',
      //   title: 'Kelurahan',
      //   placeholder: 'Kelurahan...',
      //   type: 'select-with-text',
      //   name: 'subdistrict_id',
      //   value: {
      //     label: get().field?.subdistrict_name,
      //     value: get().field?.subdistrict_id,
      //   },
      //   selectData: [],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      {
        id: 'company_id',
        title: 'Company',
        placeholder: 'Company...',
        type: 'text',
        name: 'company_id',
        value: formParam?.companyId,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
        hidden: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: UserSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitAffiliator(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateAffiliator(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
