import {Chart} from 'react-google-charts'

export function BarChart(props) {
  const options = {
    chart: {
      title: 'Donation Type',
      subtitle: 'Total Donation: 2023-2025',
    },
  }
  return (
    <>
      <div className='col-sm-6 col-md-6'>
        <Chart chartType='Bar' width='100%' height='350px' data={props.data} options={options} />
      </div>
    </>
  )
}
