import {AxiosError} from 'axios'
import {ApiCrm, ApiProgram} from '../../../../../api/api'
import {DonationAffiliateResultInterface} from '../interfaces/program-affiliate-resultInterface'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {TotalDonationByBranchInterface} from '../interfaces/total-donation-by-branch.interface'
import {TotalDonationByMitraLembagaInterface} from '../interfaces/total-donation-by-mitra-lembaga.interface'
import {TotalDonationAffiliateResultInterface} from '../interfaces/total-donation-affiliate.interface'

export interface GetAllDonationAffiliateParam {
  page?: number
  size?: number
  reference?: string
  program_id?: string
  aff_code?: string
  fundraiser_id?: string
}

export interface GetBranchParam {
  acronym?: string
  start_date?: string
  end_date?: string
}

export interface GetDashboardAdminParam {
  start_date?: string
  end_date?: string
  province_code?: string
  city_code?: string
  community_code?: string
}

export const GetAllDonationAffiliate = async (
  id: string,
  params: GetAllDonationAffiliateParam
): Promise<DonationAffiliateResultInterface> => {
  try {
    let response = await ApiProgram.get('/donations-by-affiliator/' + id, {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationAffiliateResultInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationAffiliator = async (
  id: string
): Promise<TotalDonationAffiliateResultInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-affiliator/' + id)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationAffiliateResultInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationAffiliatorKorca = async (
  id: string
): Promise<TotalDonationAffiliateResultInterface> => {
  try {
    let response = await ApiProgram.get('/sum-affiliators/' + id)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationAffiliateResultInterface = a.response?.data
    return response
  }
}

export const GetTotalCustomer = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.get('total-customers-affiliate')
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByBranch = async (
  params: GetBranchParam
): Promise<TotalDonationByBranchInterface> => {
  try {
    let response = await ApiProgram.get('total-donations-branch?', {params: params})
    return response.data as TotalDonationByBranchInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByBranchInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationBySubbranch = async (
  params: GetDashboardAdminParam
): Promise<TotalDonationByBranchInterface> => {
  try {
    let response = await ApiProgram.get('total-donations-subbranch', {
      params: params,
    })
    return response.data as TotalDonationByBranchInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByBranchInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByMitraLembaga =
  async (): Promise<TotalDonationByMitraLembagaInterface> => {
    try {
      let response = await ApiProgram.get('partner-total-donation')
      return response.data as TotalDonationByMitraLembagaInterface
    } catch (e) {
      let a = e as AxiosError
      let response: TotalDonationByMitraLembagaInterface = a.response?.data
      return response
    }
  }

export const GetTotalDonationSuccessByMitraLembaga =
  async (): Promise<TotalDonationByMitraLembagaInterface> => {
    try {
      let response = await ApiProgram.get('partner-total-transaction')
      return response.data as TotalDonationByMitraLembagaInterface
    } catch (e) {
      let a = e as AxiosError
      let response: TotalDonationByMitraLembagaInterface = a.response?.data
      return response
    }
  }

export const GetTotalCustomerByMitraLembaga =
  async (): Promise<TotalDonationByMitraLembagaInterface> => {
    try {
      let response = await ApiProgram.get('partner-total-customer')
      return response.data as TotalDonationByMitraLembagaInterface
    } catch (e) {
      let a = e as AxiosError
      let response: TotalDonationByMitraLembagaInterface = a.response?.data
      return response
    }
  }
