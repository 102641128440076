import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {
  CompanyChannelPropertiesResultInterface,
  CompanyChannelPropertiesData,
} from '../interfaces/company-channel-properties-result.interface'
import {CompanyChannelPropertiesSingleResultInterface} from '../interfaces/company-channel-properties-single-result.interface'

export interface GetAllCompanyChannelPropertiesParam {
  page?: number
  size?: number
  company_channel_id?: string
}

export const GetAllCompanyChannelProperties = async (
  params: GetAllCompanyChannelPropertiesParam
): Promise<CompanyChannelPropertiesResultInterface> => {
  try {
    let response = await ApiCrm.get('/company_channel_properties', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesResultInterface = a.response?.data
    return response
  }
}

export const ShowCompanyChannelProperties = async (
  id: string
): Promise<CompanyChannelPropertiesSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/company_channel_properties/' + id)
    return response.data as CompanyChannelPropertiesSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowCompanyChannelPropertiesByReference = async (
  reference: string
): Promise<CompanyChannelPropertiesResultInterface> => {
  try {
    let response = await ApiCrm.get('/company_channel_properties-by-reference', {
      params: {
        reference_table: reference,
      },
    })
    return response.data as CompanyChannelPropertiesResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesResultInterface = a.response?.data
    return response
  }
}

export const SubmitCompanyChannelProperties = async (
  form: CompanyChannelPropertiesData
): Promise<CompanyChannelPropertiesSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/company_channel_properties', form)
    return response.data as CompanyChannelPropertiesSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateCompanyChannelProperties = async (
  id: string,
  form: CompanyChannelPropertiesData
): Promise<CompanyChannelPropertiesSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/company_channel_properties/' + id, form)
    return response.data as CompanyChannelPropertiesSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateCompanyChannelPropertiesConnect = async (
  id: string,
  form: CompanyChannelPropertiesData
): Promise<CompanyChannelPropertiesSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/company_channel_properties_connect/' + id, form)
    return response.data as CompanyChannelPropertiesSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CompanyChannelPropertiesSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteCompanyChannelProperties = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/company_channel_properties/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingCompanyChannelProperties = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put('/company_channel_properties-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
