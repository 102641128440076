import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../auth'
import {InputDatePicker} from '../../../forms/components/input.component'
import MemberScreen from '../../crm/group-management/screens/member.screen'
import DirectionScreen from '../../program/program-affiliate/screens/direction.screen'
import DonationAffiliateDashboardPage from '../../program/program-affiliate/screens/program-affiliate.screen'
import AdminKpiComponent from '../components/admin-kpi.component'
import CustomerProfileChart from '../components/customer-profile-chart.component'
import CustomerSegmentChart from '../components/customer-segment-chart.component'
import DonationByPeriodeChartComponent from '../components/donation-by-periode-chart.component'
import DonationPerformanceChannelChartComponent from '../components/donation-performance-channel-chart.component'
import DonationTrxByPeriodeChartComponent from '../components/donation-trx-by-periode-chart.component'
import TopProgramCardComponent from '../components/top-program-card.component'
import TotalActiveProgramCardComponent from '../components/total-active-program-card.component'
import TotalCustomerCardComponent from '../components/total-customer-card.component'
import TotalDonationCardComponent from '../components/total-donation.card.component'
import TotalRoomCardComponent from '../components/total-new-case-card.component'
import {useDashboardStore} from '../states/dashboard.state'
import DashboardAdminBranchScreen from './dashboard-admin-branch.screen'
import DashboardAdminSubBranchScreen from './dashboard-admin-subbranch.screen'
import DashboardMitraLembagaScreen from './dashboard-mitra-lembaga.screen'
import DashboardAdminPusatScreen from './dashboard-admin-pusat.screen'
import DashboardTaqurScreen from '../../program/taqur/student-management/components/dashboard-taqur.component'
import ClientListComponent from '../../program/clients/components/client-list.component'

const DashboardScreen = () => {
  const {startDate, endDate, setStartDate, setEndDate} = useDashboardStore()
  const {currentUser} = useAuth()

  return (
    <>
      <div className='alert alert-primary d-flex align-items-center p-5'>
        <div className='d-flex flex-column'>
          <h4 className='mb-1 text-primary'>Perhatian</h4>
          <span>Data yang ditampilkan pada saat awal adalah data 30 hari kebelakang</span>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        <div className='col-12' style={{marginBottom: -20}}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>

        <TotalDonationCardComponent />
        <TotalCustomerCardComponent />
        <TotalActiveProgramCardComponent />

        {currentUser?.data?.role?.slug === 'admin-platform' ? (
          <></>
        ) : (
          <>
            <TotalRoomCardComponent />
            {/* Admin KPI */}
            <AdminKpiComponent />
            {/* Top 10 Programs */}
            <TopProgramCardComponent />
          </>
        )}
        {currentUser?.data?.company?.id === '21db0100-5e59-4a79-90b2-eeb6a7a7097e' ? (
          <ClientListComponent />
        ) : null}

        {/* Pencapaian Donasi */}
        <DonationByPeriodeChartComponent />

        {/* Pencapaian Transaksi */}
        <DonationTrxByPeriodeChartComponent />
        {/* <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget2 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget3 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget4 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget6 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget7 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget8 className='card-xxl-stretch mb-5 mb-xl-10' /> */}

        {/* Donatur Segmen */}
        <CustomerSegmentChart />

        {/* Donatur Profile */}
        <CustomerProfileChart />

        {/* Donation Performance By Channel */}
        <DonationPerformanceChannelChartComponent />
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {currentUser?.data?.role?.slug ? (
        <>
          {currentUser?.data?.role?.slug === 'admin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'superadmin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'lead-branch' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-branch' &&
          currentUser?.data?.company?.id === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ? (
            <DashboardScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-platform' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'user' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'relawan' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'bendahara' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'korca' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'direksi' ? <DirectionScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-provinsi' ? (
            <DonationAffiliateDashboardPage />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-kota' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-pusat' ? <DashboardAdminPusatScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-branch' &&
          currentUser?.data?.company?.id === 'ab7ae967-ad36-4874-97ab-365fff019bb5' ? (
            <DashboardAdminBranchScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-subbranch' ? (
            <DashboardAdminSubBranchScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'mitra-lembaga' ? (
            <DashboardMitraLembagaScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-taqur' ? <DashboardTaqurScreen /> : null}
        </>
      ) : null}
    </>
  )
}

export default DashboardWrapper
