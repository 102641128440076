import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import FlowBuilderListWrapper from './components/flow-builder-list.component'
import FlowBuilderMarketing from './screens/flow builder/flow-builder.marketing'
import FlowBuilderTransactional from './screens/flow builder/flow-builder.transactional'

const flowBuilderBreadcrumbs: Array<PageLink> = [
  {
    title: 'Donatur Management',
    path: '/apps/chat/flow-builder-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FlowBuilderPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='flow-builder/transactional'
          element={
            <>
              <PageTitle breadcrumbs={flowBuilderBreadcrumbs}>Flow Builder Transactional</PageTitle>
              <FlowBuilderListWrapper flowType={'transactional'} />
            </>
          }
        />
        <Route
          path='flow-builder-creator'
          element={
            <>
              <PageTitle breadcrumbs={flowBuilderBreadcrumbs}>Flow Builder Creator</PageTitle>
              <FlowBuilderTransactional />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='flow-builder/marketing'
          element={
            <>
              <PageTitle breadcrumbs={flowBuilderBreadcrumbs}>Flow Builder Marketing</PageTitle>
              <FlowBuilderListWrapper flowType={'marketing'} />
            </>
          }
        />
        <Route
          path='flow-builder-creator'
          element={
            <>
              <PageTitle breadcrumbs={flowBuilderBreadcrumbs}>Flow Builder Creator</PageTitle>
              <FlowBuilderMarketing />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default FlowBuilderPage
