// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import ClassicEditor from './classic-editor';
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'

interface RichTextV2Props {
  title?: string
  value?: any
  onChange?: (event: any, editor: any) => void
  onBlur?: (event: any, editor: any) => void
  onFocus?: (event: any, editor: any) => void
  name?: string
  disabled?: boolean
  required?: boolean
  getEditor?: (editor: any) => void
}

const RichTextV2: React.FC<RichTextV2Props> = ({
  title = '',
  value,
  onChange,
  onBlur,
  onFocus,
  name,
  disabled = false,
  required = true,
  getEditor,
}) => {
  const uploadAdapter = (loader: any) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append('file', file)
            let headers = new Headers()
            headers.append('storage-api-key', process.env.REACT_APP_STORAGE_KEY)
            fetch(process.env.REACT_APP_STORAGE_URL + '/storage/single', {
              method: 'post',
              headers: headers,
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  // default: `${process.env.REACT_APP_ASSETS_URL}/${res.data.id}`,
                  default: `${process.env.REACT_APP_ASSETS_URL}/${res.data.id}?w=534&fit=crop&auto=format,compress`,
                })
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }

  return (
    <div className='col-md-12 col-md-12 mb-5 prose'>
      <label className={`form-label`}>
        {title}
        {required === true && <span style={{marginLeft: 5, color: 'red'}}>*</span>}
      </label>
      <CKEditor
        editor={Editor}
        data={value}
        name={name}
        disabled={disabled}
        config={{
          extraPlugins: [uploadPlugin],
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        onReady={(editor: any) => {
          // You can store the "editor" and use when it is needed.
          getEditor(editor)
        }}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}

export default RichTextV2
